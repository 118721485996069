body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
    background-color: #757575;
}

/* ::-webkit-scrollbar-thumb:horizontal {
    background-color: #43fe32;
}
::-webkit-scrollbar-thumb:vertical {
    background-color: #843344;
} */

::-webkit-scrollbar-thumb:hover {
    background-color: #212121;
}

@media (max-width: 600px), (max-height: 600px) {
    ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #757575;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #212121;
    }
}
